import About from "./components/About";
import Caroussel from "./components/Caroussel";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import NextDeparture from "./components/NextDeparture";
import Prices from "./components/Prices";
import Services from "./components/Services";

function App() {
  return (
    <>
      <Navigation />

      <Caroussel />

      <About />

      <Services />

      <Prices />

      <NextDeparture />

      <Contact />

      <Footer />
    </>
  );
}

export default App;
