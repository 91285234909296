import React from 'react'

import service_1 from "../images/service-1.jpg"
import service_2 from "../images/service-2.jpg"
import service_3 from "../images/service-3.jpg"
import service_4 from "../images/service-4.jpg"
import service_5 from "../images/service-5.jpg"
import service_6 from "../images/service-6.jpg"

const Services = () => {
  return (
    <div className="container-xxl py-5" id='services'>
        <div className="container py-5">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="text-secondary text-uppercase">Our Services</h6>
                <h1 className="mb-5">Explore Our Services</h1>
            </div>
            <div className="row g-4">
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service_1} alt=""/>
                        </div>
                        <h4 className="mb-3">Air Freight</h4>
                        <p>Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem diam.</p>
                        <a className="btn-slide mt-2" href="/"><i className="fa fa-arrow-right"></i><span>Read More</span></a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service_2} alt=""/>
                        </div>
                        <h4 className="mb-3">Ocean Freight</h4>
                        <p>Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem diam.</p>
                        <a className="btn-slide mt-2" href="/"><i className="fa fa-arrow-right"></i><span>Read More</span></a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service_3} alt=""/>
                        </div>
                        <h4 className="mb-3">Road Freight</h4>
                        <p>Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem diam.</p>
                        <a className="btn-slide mt-2" href="/"><i className="fa fa-arrow-right"></i><span>Read More</span></a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service_4} alt=""/>
                        </div>
                        <h4 className="mb-3">Train Freight</h4>
                        <p>Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem diam.</p>
                        <a className="btn-slide mt-2" href="/"><i className="fa fa-arrow-right"></i><span>Read More</span></a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service_5} alt=""/>
                        </div>
                        <h4 className="mb-3">Customs Clearance</h4>
                        <p>Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem diam.</p>
                        <a className="btn-slide mt-2" href="/"><i className="fa fa-arrow-right"></i><span>Read More</span></a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service_6} alt=""/>
                        </div>
                        <h4 className="mb-3">Warehouse Solutions</h4>
                        <p>Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem diam.</p>
                        <a className="btn-slide mt-2" href="/"><i className="fa fa-arrow-right"></i><span>Read More</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Services