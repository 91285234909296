import React from 'react'

const Navigation = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light shadow border-top border-5 border-primary sticky-top p-0">
        <a href="/" className="navbar-brand bg-primary d-flex align-items-center px-4 px-lg-5">
            <h2 className="mb-2 text-white">Leap Logistics</h2>
        </a>
        <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto p-4 p-lg-0">
                <a href="/" className="nav-item nav-link">Accueil</a>
                <a href="/#about" className="nav-item nav-link">A propos</a>
                <a href="#services" className="nav-item nav-link">Services</a>
                <a href="#prix" className="nav-item nav-link">Prix</a>
                <a href="#contact" className="nav-item nav-link">Contact</a>
                <div className="nav-item dropdown">
                    <a href="/#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Suivi</a>
                    <div className="dropdown-menu fade-up m-0">
                        <a href="/" className="nav-item nav-link">Suivi de colis</a>
                        <a href="/" className="nav-item nav-link">Prochain départ</a>
                        <a href="/" className="nav-item nav-link">Destination</a>
                    </div>
                </div>
            </div>
            <h4 className="m-0 pe-lg-5 d-none d-lg-block"><i className="fa fa-headphones text-primary me-3"></i>+86 19102042856</h4>
        </div>
    </nav>
  )
}

export default Navigation