import React from 'react'

const Footer = () => {
  return (
    <div className="container-fluid bg-dark text-light footer pt-5 wow fadeIn" data-wow-delay="0.1s" style={{marginTop: "6rem"}}>
        <div className="container py-5">
            <div className="row d-flex justify-content-between g-5">
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Address</h4>
                    <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>123 Street, New York, USA</p>
                    <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+012 345 67890</p>
                    <p className="mb-2"><i className="fa fa-envelope me-3"></i>info@example.com</p>
                    <div className="d-flex pt-2">
                        <a className="btn btn-outline-light btn-social" href="/"><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-outline-light btn-social" href="/"><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-outline-light btn-social" href="/"><i className="fab fa-youtube"></i></a>
                        <a className="btn btn-outline-light btn-social" href="/"><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
            
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Liens utils</h4>
                    <a className="btn btn-link" href="#about">A propos</a>
                    <a className="btn btn-link" href="#contact">Contact</a>
                    <a className="btn btn-link" href="#services">Services</a>
                    <a className="btn btn-link" href="/">Terms & Condition</a>
                    <a className="btn btn-link" href="/">Support</a>
                </div>
                
            </div>
        </div>
        <div className="container">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a className="border-bottom" href="/">Leap Logistics</a>, All Right Reserved.
                    </div>
                    {/* <div className="col-md-6 text-center text-md-end">
                        Designed By <a className="border-bottom" href="https://htmlcodex.com">HTML Codex</a>
                        <br />Distributed By <a className="border-bottom" href="https://themewagon.com" target="_blank">ThemeWagon</a>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer